import Vue from 'vue'
import VueI18n from 'vue-i18n'
import es from '@/i18n/es.json'
import it from '@/i18n/it.json'

Vue.use(VueI18n)

const messages = {
    es,
    it,
}

const defaultLocale = 'es'
export let locale;
try {
    let url = window.location.hostname;

    if (url === 'primasessione.centrielha.it') {
        locale = 'it';
    } else {
        locale = 'es';
    }
} catch (err) {
    console.log(err)
    locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');
}
const i18n = new VueI18n({
    messages,
    locale: locale ? locale : defaultLocale,
    fallbackLocale: 'es'
})

export default i18n
